/**
 * This Wizard component is very similar to the one in shopping-pwa and mainly contains:
 * - A start and end date input/picker (datepicker)
 * - A guests and rooms picker
 * - A submit button
 * All of which are tracked. When the user chooses their dates/guests/rooms and clicks
 * submit, they get sent to HSR.
 *
 */
// External libraries
import * as React from "react";
import { observer } from "mobx-react";
import { action, observable, makeObservable } from "mobx";
import { QueryRoute, updateSearch, closeDialog } from "bernie-core";
import { Layer } from "bernie-view";
import { ViewLarge, ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

// UITK
import { TypeaheadSelection } from "@egds/react-core/typeahead";
import { UitkDatePickerTriggeredSelector } from "@egds/react-core/date-picker";

// Components
import {
  Action,
  FlexTrackingInfo,
  sendDelayedTrackEvent,
  sendImmediateTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import {
  getHSRFilterCode,
  getHSRFilters,
  getHSRStarRatingCode,
  getPropertyTypeCode,
  getBEXApiFilters,
} from "src/components/utility/ContextUtil";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { getNextFriday } from "src/components/utility/DateUtils";
import { withStores } from "stores";
import { searchButtonCopyL10n } from "components/flexComponents/WizardHotelPWA/components/SearchButtonCopyL10n";
import {
  FormFieldModel,
  GuestDialogProps,
  HiddenSearchDataProps,
  TypeaheadSelectionProps,
  WizardHotelPWAFlexModuleResult,
  WizardHotelPWAProps,
} from "./typings";
import { WizardDialogTrigger } from "./components/WizardDialogTrigger";
import { GuestDialog, GuestField } from "./components/GuestField";
import { HiddenSearchData } from "./components/HiddenSearchData";
import { SubmitButton, SubmitButtonProps } from "./components/SubmitButton";
import { locKeys } from "./components/l10n";
import { SearchForm } from "./components/SearchForm";
import { getExperimentBucket } from "../../utility/experiment";
import { DatePicker } from "./components/DatePicker";
import { StickyCTAMobile } from "./components/StickyCTAMobile";
import { DestinationField } from "./components/DestinationField";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridTrack,
  MinMax,
} from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading } from "@egds/react-core/text";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { DEFAULT_BOOKING_WINDOW, DEFAULT_OPTIMIZATION_WIDTH } from "./utils/constants";
import { UitkCard } from "@egds/react-core/cards";

enum PageType {
  AIRPORT = "airport",
  POI = "point_of_interest",
  VACATION_RENTAL = "vacation_rental",
}

enum StickWhenType {
  WILL_EXIT = "willExit",
  HAS_EXITED = "hasExited",
}

const SORT_DISTANCE_VALUE = "distance";

interface WizardHotelPWAState {
  startInvalidMessage: string;
  endInvalidMessage: string;
}

export class WizardHotelPWAComponent extends React.Component<WizardHotelPWAProps> {
  public startDate = "";
  public endDate = "";
  public guestsVal = "";
  public adults = ""; // Example: "2", "2,1,2" (represents each room's total adults)
  public children = ""; // Example: "1_0, 1_7" (represents a child and their age)
  public backTracking = {
    isProgrammatic: false,
    dialog: "",
  };
  public destination = "";

  public ttla = "";
  public regionId = "";
  public selected = "";
  public latLong = "";
  public submitButtonClicked = false;
  public destinationFieldProperties: FormFieldModel;
  public shouldShowExpandedWizardInHsr = false;
  public wizardContainerIsOutOfWindow = false;
  public componentsWithJs = false;
  // will auto open the new date picker, given the right experiment and desktop
  public newDatePickerIsOpen = false;

  private focusGuestPicker: React.RefObject<HTMLInputElement>;

  private wizardFormRef: React.RefObject<HTMLFormElement>;

  private wizardRef: React.RefObject<HTMLDivElement>;

  private wizardWrapperRef: React.RefObject<HTMLDivElement>;

  private destinationRef: React.RefObject<HTMLInputElement>;

  private showDestinationField = false;

  private isStorefrontPage = false;

  private isLargeScreenAndStickWhenWillExit: boolean;

  private shouldRouteToHsrFromCalendar = false;

  private isDesktop: boolean;

  private formatDate: (date: Date, options: any) => string;

  private formatText: (key: string, ...args: any[]) => string;

  private mediumDateFormat: string;

  private options: WizardHotelPWAFlexModuleResult;

  public state: Readonly<WizardHotelPWAState> = {
    startInvalidMessage: "",
    endInvalidMessage: "",
  };

  private isCalendarKeptOpen: boolean;

  private isEarlyStickyTransition: boolean;
  private isBEXSearchOptimizationForDesktopVariant: boolean;

  // Not its own component as it is not yet known what the best way to refactor the wizard is yet.
  // We need both isMobile and isTablet, because the sticky wizard is for tablet and desktop, and the new
  // date picker is for desktop only.
  private WizardForm = observer(({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => {
    const { context, openPlayBack, compositionStore } = this.props;

    const hsrFilterCode = getHSRFilterCode(context);
    const bexApiFilters = getBEXApiFilters(context);
    const hsrFilters = getHSRFilters(context);
    const hsrStarRatingCode = getHSRStarRatingCode(context);
    const propertyType = getPropertyTypeCode(context);
    const pageHeading = compositionStore?.pageHeading;
    const pageSubHeading = compositionStore?.pageSubHeadline;

    const hiddenSearchDataProps: HiddenSearchDataProps = {
      langId: context.langId,
      children: this.children,
      adults: this.adults,
      endDate: this.endDate,
      startDate: this.startDate,
      regionId: this.regionId,
      selected: this.selected,
      latLong: this.latLong,
      expandForm: this.shouldShowExpandedWizardInHsr,
      hsrFilterCode,
      hsrFilters,
      starRating: hsrStarRatingCode,
      sort: this.getSortFilterValue(context),
      propertyType,
      ttla: this.ttla,
      bexApiFilters,
      openPlayBack,
    };

    //Logic to determine if the wizard should be sticky
    const { stickWhen, showStickyCTA, showStickyWizard } = this.options;

    //Logic to determine if the wizard should be sticky
    const isSticky = this.wizardContainerIsOutOfWindow && !isMobile && !!showStickyWizard;
    const isStickyMobileEnabled = isMobile && !!showStickyWizard;

    const isLargeScreen = !(isMobile || isTablet);

    this.isLargeScreenAndStickWhenWillExit = isLargeScreen && stickWhen === StickWhenType.WILL_EXIT;
    const isBottomSlideView = this.props.templateComponent.config.view === "bottomSlideView";

    const mainFormWizardClassNames = () => {
      let className = "";

      if (isSticky) {
        className = "sticky";
      }

      if (isSticky && this.isLargeScreenAndStickWhenWillExit) {
        className += "stickyWillExit";
      }

      return className;
    };

    const stickyCTA = this.isAffinityPageRegExMatch(context)
      ? this.formatText(locKeys.stickyWizardAffinityTitle)
      : this.formatText(locKeys.stickyWizardTitle);
    const wizardFormTestId = isSticky ? "wizard-sticky" : "wizard";

    // showStickyCTA is a new field in FM and should have a default value of undefined
    const shouldShowStickyCTA = isSticky && (showStickyCTA || showStickyCTA === undefined);
    const setDestinationRef = (element: React.RefObject<HTMLInputElement>) => (this.destinationRef = element);

    const wizardColumns: UitkLayoutConditionalGridTrack = this.showDestinationField
      ? {
          small: [MinMax("0x", "12fr")],
          medium:
            this.isBEXSearchOptimizationForDesktopVariant && !isSticky
              ? [MinMax("0x", "12fr")]
              : [MinMax("0x", "6fr"), MinMax("0x", "6fr")],
          large:
            this.isBEXSearchOptimizationForDesktopVariant && !isSticky
              ? [MinMax("0x", "12fr")]
              : [MinMax("0x", "4fr"), MinMax("0x", "4fr"), MinMax("0x", "2fr"), MinMax("0x", "2fr")],
        }
      : {
          small: [MinMax("0x", "12fr")],
          medium:
            this.isBEXSearchOptimizationForDesktopVariant && !isSticky
              ? [MinMax("0x", "12fr")]
              : [MinMax("0x", "6fr"), MinMax("0x", "4fr"), MinMax("0x", "2fr")],
        };
    const submitButtonProps: SubmitButtonProps = {
      moduleName: this.getModuleNameForTracking(),
      submitButtonText: this.getSubmitButtonText(),
      rfrr: "hotel.search",
    };

    const datePickerProps = {
      startDate: this.startDate,
      endDate: this.endDate,
      isOpen: this.newDatePickerIsOpen,
      onOpen: this.onDatePickerOpen,
      onDismiss: this.onDatePickerDismiss,
      onSubmit: this.onDatePickerSubmit,
      startInvalidMessage: this.state.startInvalidMessage,
      endInvalidMessage: this.state.endInvalidMessage,
      mediumDateFormat: this.mediumDateFormat,
    };

    return (
      <>
        {isStickyMobileEnabled && (
          <StickyCTAMobile
            isSticky={this.wizardContainerIsOutOfWindow}
            moduleName={this.getModuleNameForTracking()}
            submitButtonText={this.formatText(locKeys.stickyWizardCallToAction)}
            scrollTarget={this.wizardFormRef}
          />
        )}
        {!isBottomSlideView &&
          this.isBEXSearchOptimizationForDesktopVariant &&
          this.options.includePageHeading &&
          isMobile && (
            <UitkHeading tag="h1" size={4} className={"titlePage"}>
              {pageHeading}
            </UitkHeading>
          )}

        {!isBottomSlideView &&
          this.isBEXSearchOptimizationForDesktopVariant &&
          this.options.includePageSubHeading &&
          isMobile && (
            <UitkSpacing padding={{ small: { blockend: "half" }, medium: { blockend: "three", blockstart: "one" } }}>
              <UitkHeading tag="h2" size={6} className={"subTitlePage"}>
                {pageSubHeading}
              </UitkHeading>
            </UitkSpacing>
          )}
        <form
          noValidate
          action="/Hotel-Search"
          onSubmit={this.shouldSubmit}
          ref={this.wizardFormRef}
          autoComplete="off"
          className={mainFormWizardClassNames()}
          data-testid={wizardFormTestId}
        >
          <HiddenSearchData {...hiddenSearchDataProps} />
          {shouldShowStickyCTA && (
            <UitkHeading tag="h1" size={5}>
              {stickyCTA}
            </UitkHeading>
          )}
          <UitkSpacing padding={isBottomSlideView && isMobile ? { blockstart: "three" } : { block: "three" }}>
            <UitkLayoutGrid
              space="three"
              columns={wizardColumns}
              justifyContent="center"
              className={`${this.isLargeScreenAndStickWhenWillExit ? "centerWizard" : ""}`}
            >
              {this.showDestinationField && (
                <UitkLayoutGridItem>
                  <div>
                    <DestinationField
                      setDestinationRef={setDestinationRef}
                      formFieldModel={this.destinationFieldProperties}
                      openDestinationTypeaheadTrack={this.openDestinationTypeaheadTrack}
                      updateDestinationField={this.updateDestinationField}
                      locale={context.locale}
                      guid={context.guid}
                      siteId={context.site.id.toString()}
                      invalid={this.validate("destination")}
                      destination={this.destination}
                    />
                  </div>
                </UitkLayoutGridItem>
              )}
              <UitkLayoutGridItem>
                <div>
                  <DatePicker {...datePickerProps} />
                </div>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <div>
                  <GuestField
                    guestsVal={this.guestsVal}
                    open={this.clickGuests}
                    focusInput={this.focusGuestPicker}
                    invalid={this.validate("travelers")}
                    componentWithJs={this.componentsWithJs}
                    isMobile={isMobile}
                    context={context}
                  />
                </div>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <div>
                  <SubmitButton {...submitButtonProps} />
                </div>
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkSpacing>
        </form>
      </>
    );
  });

  constructor(props: WizardHotelPWAProps) {
    super(props);

    makeObservable(this, {
      startDate: observable,
      endDate: observable,
      guestsVal: observable,
      adults: observable,
      children: observable,
      backTracking: observable,
      destination: observable,
      ttla: observable,
      regionId: observable,
      selected: observable,
      latLong: observable,
      submitButtonClicked: observable,
      destinationFieldProperties: observable,
      shouldShowExpandedWizardInHsr: observable,
      wizardContainerIsOutOfWindow: observable,
      componentsWithJs: observable,
      newDatePickerIsOpen: observable,
      updateDestinationField: action,
      setMultiPropParam: action,
      exitDialog: action,
      clickGuests: action,
      saveGuests: action,
      closeGuestPicker: action,
      onScroll: action,
      onDatePickerOpen: action,
    });

    const {
      startDate,
      context,
      templateComponent,
      flexModuleModelStore,
      daysToAdd = DEFAULT_BOOKING_WINDOW,
      isCalendarKeptOpen = false,
      isEarlyStickyTransition = false,
      isBEXSearchOptimizationForDesktopVariant = false,
    } = props;
    const { formatText, formatDate } = this.props.l10n;
    this.formatDate = formatDate;
    this.formatText = formatText;

    this.isCalendarKeptOpen = isCalendarKeptOpen;
    this.isEarlyStickyTransition = isEarlyStickyTransition;
    this.isBEXSearchOptimizationForDesktopVariant = isBEXSearchOptimizationForDesktopVariant;

    const model =
      (flexModuleModelStore.getModel(templateComponent.metadata.id) as WizardHotelPWAFlexModuleResult) || null;

    if (model) {
      this.options = model;
      this.isDesktop = !context?.deviceInformation?.mobile && !context?.deviceInformation?.tablet;
      this.showDestinationField = !!this.options.showDestinationField;

      this.isStorefrontPage = context.searchContext.pageType.indexOf("Storefront") > -1;
      this.setInitialDates(startDate, daysToAdd);
      this.destinationFieldProperties = SearchForm.HotelOnly;

      // if there is no location search context, there should be a destination field otherwise you can't search
      if (!context.searchContext.location) {
        this.showDestinationField = true;
      } else {
        this.destination = context.searchContext.location.extendedName;
        this.regionId = context.searchContext.location.id;
      }

      this.newDatePickerIsOpen = Boolean(this.isDesktop && this.options.desktopAutoOpenCalendar);

      this.mediumDateFormat = this.options.enableWeekdayFormatting ? "MMMEd" : "MMMd";

      this.focusGuestPicker = React.createRef();
      this.wizardFormRef = React.createRef();
      this.wizardRef = React.createRef();
      this.wizardWrapperRef = React.createRef();

      // It is probably not storefront if there's no destination field but we are also testing the dest field on HTG
      if (!this.isStorefrontPage) {
        this.shouldRouteToHsrFromCalendar = true;
      }
    }
  }

  /**
   * Sets the initial wizard interval that our users will start and end their trip.
   * @param context - Required to pass to isVariantEnabled to access the experiments
   * @param startDate - The passed start date that the user will by default start
   *  their trip dates with
   */
  private setInitialDates(startDate = new Date(), daysToAdd: number): void {
    // If non-storefront page and Desktop, don't put dates
    if (!this.isStorefrontPage && this.isDesktop) {
      return;
    }

    // Test winner (https://tnl.prod.expedia.com/#experiment-detail/32463): interval 30 days in the future
    // We only want for non-storefront
    // If affinity page not apply interval 30 days in the future
    if (!this.isStorefrontPage && !this.isAffinityPageRegExMatch(this.props.context)) {
      startDate.setDate(startDate.getDate() + daysToAdd);
    }

    //Only page affinity has check-in and check-out date 2 weeks from today
    const today = new Date(startDate);
    const checkInDate = this.isAffinityPageRegExMatch(this.props.context) ? getNextFriday(today) : today;
    const checkOutDate = new Date(checkInDate.getTime() + 24 * 60 * 60 * 1000);
    this.startDate = this.formatDate(checkInDate, { raw: "yyyy-MM-dd" });
    this.endDate = this.formatDate(checkOutDate, { raw: "yyyy-MM-dd" });
  }

  private removeAutoOpenBehaviorFromDatePicker = (options: WizardHotelPWAFlexModuleResult): void => {
    const { stickWhen } = options;

    if (!options || !stickWhen || stickWhen !== StickWhenType.WILL_EXIT) {
      return;
    }

    // For DX pages only
    this.newDatePickerIsOpen = false;
  };

  public componentDidMount() {
    window.addEventListener("popstate", this.listenToBackButton);
    window.addEventListener("scroll", this.onScroll);
    this.removeAutoOpenBehaviorFromDatePicker(this.options);

    /**
     * Values set if there is rehydratation
     */
    this.adults = "2";
    this.guestsVal = this.formatText(locKeys.roomsGuests, 1, Number(this.adults));
    this.componentsWithJs = true;
  }

  public componentWillUnmount() {
    window.removeEventListener("popstate", this.listenToBackButton);
    window.removeEventListener("scroll", this.onScroll);
  }

  private listenToBackButton = () => {
    //wizard.hotel.back-button
    const datepickerTrackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      rfrr: "hotel",
      action: Action.BACK_BUTTON,
    };

    //wizard.hotel.guests.back-button
    const guestsTrackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      rfrr: "hotel.guests",
      action: Action.BACK_BUTTON,
    };

    if (!this.backTracking.isProgrammatic && this.backTracking.dialog !== "") {
      const trackingInfo =
        this.backTracking.dialog === WizardDialogTrigger.GUESTPICKER ? guestsTrackingInfo : datepickerTrackingInfo;
      sendDelayedTrackEvent(trackingInfo, this.props.analytics);
      this.backTracking.dialog = "";
    }
    this.backTracking.isProgrammatic = false;
  };

  public openDestinationTypeaheadTrack = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      action: Action.CLICK,
      rfrr: `hotel.destination.open`,
      linkName: "Destination dialog action",
    };
    sendDelayedTrackEvent(trackingInfo, this.props.analytics);
  };

  public getSortFilterValue = (context: ExtendedContextStore) => {
    const { searchContext } = context;
    const isAirport = searchContext.location && searchContext.location.type === PageType.AIRPORT;
    const isPOI = searchContext.location && searchContext.location.type === PageType.POI;
    const lob = searchContext.lob;

    if (lob === PageType.VACATION_RENTAL) {
      return lob.toUpperCase();
    }

    return isAirport || isPOI ? SORT_DISTANCE_VALUE : "";
  };

  public updateDestinationField = (selection: TypeaheadSelection) => {
    const regionName = this.props.templateComponent.config.view === "expando" ? `.${this.regionId}` : "";
    const trackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      action: Action.CLICK,
      rfrr: `hotel.destination.change${regionName}`,
      linkName: "Destination dialog action",
    };
    sendDelayedTrackEvent(trackingInfo, this.props.analytics);
    const { data, term } = selection as TypeaheadSelectionProps;
    const { regionId, selected, location, hierarchyInfo } = data;

    this.destination = term;
    this.regionId = regionId || "";
    this.selected = selected || "";
    this.latLong = location?.lat && location?.long ? `${data.location.lat},${data.location.long}` : "";
    this.ttla = hierarchyInfo?.airport?.airportCode;
  };

  public setMultiPropParam = (elements: HTMLFormControlsCollection) => {
    for (const element of elements) {
      if (element.id === "multiProp") {
        element.setAttribute("value", "true");
      }
    }
  };

  public exitDialog = (dialogType?: WizardDialogTrigger) => {
    this.props.history.goBack();

    let focus;
    switch (dialogType) {
      case WizardDialogTrigger.GUESTPICKER:
        focus = this.focusGuestPicker;
        break;
    }

    if (focus) {
      const focusInput = focus.current;
      if (focusInput && typeof focusInput.focus === "function") {
        focusInput.focus();
      }
    }
    this.backTracking.isProgrammatic = true;
    this.backTracking.dialog = "";
  };

  public clickGuests = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      action: Action.CLICK,
      rfrr: "hotel.guests.open",
      linkName: "Guests dialog action",
    };

    sendDelayedTrackEvent(trackingInfo, this.props.analytics);
    updateSearch({
      history: this.props.history,
      location: this.props.location,
      newParams: { pwaDialog: "guestdialog" },
    });

    this.backTracking.dialog = WizardDialogTrigger.GUESTPICKER;
  };

  public saveGuests = (adults: string, children: string, displaySummary?: string) => {
    this.adults = adults;
    this.children = children;

    if (displaySummary) {
      this.guestsVal = displaySummary;
    }

    this.exitDialog(WizardDialogTrigger.GUESTPICKER);
  };

  public closeGuestPicker = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      rfrr: "hotel.guests.close",
      action: Action.CLICK,
      linkName: "Guests dialog action",
    };

    sendDelayedTrackEvent(trackingInfo, this.props.analytics);
    this.exitDialog(WizardDialogTrigger.GUESTPICKER);
  };

  public onScroll = () => {
    const wizardWasOutOfWindow = this.wizardContainerIsOutOfWindow;

    // If isEarlyStickyTransition (TNL in variant && DLP) && desktop view:
    if (this.isEarlyStickyTransition && this.isDesktop) {
      // Take a ref on the wrapper and do the calculation from its bottom reduced by the form's height:
      // Notes:
      // - wrapper's top is not usable, because the page title's height can large (title in 2 lines): too early switch to sticky
      // - wizardFormRef's top alone (without wrapper) is not usable because of the issues with the global header
      const currentWrapperRef =
        (this.wizardWrapperRef?.current?.getBoundingClientRect().bottom ?? 0) -
        (this.wizardFormRef?.current?.clientHeight ?? 0);
      this.wizardContainerIsOutOfWindow = currentWrapperRef < 0;
    } else if (this.wizardRef && this.wizardRef.current) {
      const currentWizardRef = this.isLargeScreenAndStickWhenWillExit
        ? this.wizardRef.current.getBoundingClientRect().top
        : this.wizardRef.current.getBoundingClientRect().bottom;
      this.wizardContainerIsOutOfWindow = currentWizardRef < 0;
    }

    // This condition runs exactly when the calendar is switched from sticky to non-sticky or vice versa
    if (wizardWasOutOfWindow !== this.wizardContainerIsOutOfWindow) {
      // If isCalendarKeptOpen (TNL in variant && DLP) && desktop view && already open: keep it open:
      if (this.isCalendarKeptOpen && this.isDesktop && this.newDatePickerIsOpen) {
        this.newDatePickerIsOpen = true;
      } else {
        // This prevents the new date picker to stay opened when switching between sticky/non-sticky wizard: default behavior
        this.newDatePickerIsOpen = false;
      }
    }
  };

  /**
   * Switch the open state of the DatePicker
   */
  public toggleDatePicker() {
    this.newDatePickerIsOpen = !this.newDatePickerIsOpen;
  }

  /**
   * Callback when the Datepicker is click open.
   * @param dateType Start or End date
   */
  public onDatePickerOpen = (dateType: UitkDatePickerTriggeredSelector) => {
    this.toggleDatePicker();

    const rfrr = dateType === UitkDatePickerTriggeredSelector.START ? "check-in" : "check-out";
    const trackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      action: Action.CLICK,
      rfrr: `hotel.${rfrr}`,
      linkName: "Datepicker dialog action",
    };

    sendDelayedTrackEvent(trackingInfo, this.props.analytics);
  };

  /**
   * Callback when the Datepicker is dismissed, it should close the mobile dialog
   * and change the state of the visibility of the desktop component
   * @param isMobile Whether the view is mobile or not
   * @param noUpdate Whether the view requires update or not
   */
  public onDatePickerDismiss = (isMobile: boolean, noUpdate?: boolean) => {
    if (!noUpdate) {
      this.toggleDatePicker();
    }

    const trackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      rfrr: "hotel.close",
      action: Action.CLICK,
      linkName: "Datepicker dialog action",
    };

    sendDelayedTrackEvent(trackingInfo, this.props.analytics);
  };

  /**
   * Callback when the DatePicker is submitted.
   * @param startDate Selected Start Date
   * @param endDate Selected End  Date
   * @param isMobile Whether the device is mobile or not
   */
  public onDatePickerSubmit = (startDate: Date, endDate: Date, isMobile = false) => {
    const { analytics, history, location } = this.props;
    const trackingInfo: FlexTrackingInfo = {
      moduleName: this.getModuleNameForTracking(),
      rfrr: "hotel.done-btn",
      action: Action.CLICK,
      linkName: "Datepicker Done action",
    };
    sendImmediateTrackEvent(trackingInfo, analytics);

    if (startDate || endDate) {
      this.startDate = this.formatDate(startDate, { raw: "yyyy-MM-dd" });
      this.endDate = this.formatDate(endDate, { raw: "yyyy-MM-dd" });
      this.validateAndGetFormErrors();
    }

    if (isMobile && this.shouldRouteToHsrFromCalendar) {
      // When tnl 37559 in bucket 0 and mobile device, when selecting dates it should send to HSR directly
      this.shouldShowExpandedWizardInHsr = true;
      // Using setTimeout to add form submission to a "queue" in order to wait for dates to update
      setTimeout(() => {
        if (this.wizardFormRef && this.wizardFormRef.current && this.validateAndGetFormErrors().length === 0) {
          this.wizardFormRef.current.submit();
        } else {
          closeDialog({ history, location });
        }
      }, 0);
    } else {
      // Close dialog when in mobile and stop showing datepicker in all devices
      if (isMobile) {
        closeDialog({ history, location });
      }
      this.toggleDatePicker();
    }
  };

  private shouldSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    this.submitButtonClicked = true;
    if (this.validateAndGetFormErrors().length > 0) {
      e.preventDefault();
    } else {
      this.setMultiPropParam(e.currentTarget.elements);
    }
  };

  private validate(key: string): string {
    const checkInDate = new Date(this.startDate);
    const checkOutDate = new Date(this.endDate);
    const destination = this.destination;

    if (key === "startDate") {
      if (!this.isValidDate(checkInDate)) {
        return this.formatText(locKeys.invalidFromDate);
      }
    }

    if (key === "endDate") {
      if (!this.isValidDate(checkOutDate)) {
        return this.formatText(locKeys.invalidToDate);
      }

      if (this.isValidDate(checkInDate)) {
        const maxEndDate = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate() + 28);

        if (checkOutDate < checkInDate) {
          return this.formatText(locKeys.invalidDateRangeEndBeforeStart);
        }

        if (checkOutDate > maxEndDate) {
          return this.formatText(locKeys.invalidDateRangeEndOverLimit);
        }
      }
    }

    if (key === "destination" && destination === "" && this.submitButtonClicked) {
      return this.formatText(locKeys.invalidDestination);
    }

    return "";
  }

  public validateAndGetFormErrors = (event?: React.FormEvent<HTMLFormElement>) => {
    const errors = [];
    const startDateError = this.validate("startDate");
    const endDateError = this.validate("endDate");
    const destinationError = this.validate("destination");

    this.setState({
      startInvalidMessage: startDateError,
      endInvalidMessage: endDateError,
    });

    if (startDateError) {
      errors.push(startDateError);
    }
    if (endDateError) {
      errors.push(endDateError);
    }
    if (destinationError) {
      this.destinationRef.current?.focus();
      errors.push(destinationError);
    }

    if (errors.length > 0 && event && typeof event.preventDefault === "function") {
      event.preventDefault();
    }

    return errors;
  };

  private isValidDate(date: Date) {
    return date && date.toString() !== "Invalid Date";
  }

  public render() {
    const { templateComponent, compositionStore, l10n, additionalClasses = "" } = this.props;

    if (!this.options || !templateComponent) {
      return null;
    }

    const pageHeading = compositionStore?.pageHeading;
    const pageSubHeading = compositionStore?.pageSubHeadline;

    const adults = this.adults || "2";

    const guestDialogProps: GuestDialogProps = {
      adults,
      children: this.children,
      moduleName: this.getModuleNameForTracking(),
      onDismiss: this.closeGuestPicker,
      onSave: this.saveGuests,
      l10n,
    };

    const WizardForm = this.WizardForm;

    const {
      metadata: { id },
      config: { fmTitleId },
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const isBottomSlideView = this.props.templateComponent.config.view === "bottomSlideView";
    const bottomSlideView = isBottomSlideView ? "bottomSlideView" : "";
    const bexSearchOptimizationVariant = this.isBEXSearchOptimizationForDesktopVariant ? "search-form-bex" : "";

    const classNameList = `WizardHotelPWA fullScreenWidth ${additionalClasses} ${bottomSlideView} ${bexSearchOptimizationVariant}`;

    const pageHeadingDiv = this.options.includePageHeading && (
      <UitkHeading
        tag="h1"
        size={4}
        className={isBottomSlideView || this.isBEXSearchOptimizationForDesktopVariant ? undefined : "titlePage"}
      >
        {pageHeading}
      </UitkHeading>
    );
    const pageSubHeadingDiv = this.options.includePageSubHeading && (
      <UitkSpacing padding={{ small: { blockend: "half" }, medium: { blockend: "three", blockstart: "one" } }}>
        <UitkHeading
          tag="h2"
          size={6}
          className={isBottomSlideView || this.isBEXSearchOptimizationForDesktopVariant ? undefined : "subTitlePage"}
        >
          {pageSubHeading}
        </UitkHeading>
      </UitkSpacing>
    );

    return (
      <div id={id} data-fm={fmId} data-fm-title-id={fmTitleId} className={classNameList} ref={this.wizardRef}>
        <div className="WizardHotelPWA-wrapper" ref={this.wizardWrapperRef}>
          {isBottomSlideView || this.isBEXSearchOptimizationForDesktopVariant ? null : pageHeadingDiv}
          {isBottomSlideView || this.isBEXSearchOptimizationForDesktopVariant ? null : pageSubHeadingDiv}
          <Viewport>
            <ViewSmall>
              {isBottomSlideView ? (
                <UitkSpacing padding={{ blockstart: "sixteen" }}>
                  <div>
                    <UitkCard padded className="elevation">
                      <UitkSpacing padding={{ blockstart: "four", blockend: "four" }}>
                        <div>
                          {pageHeadingDiv}
                          {pageSubHeadingDiv}
                          <WizardForm isMobile isTablet />
                        </div>
                      </UitkSpacing>
                    </UitkCard>
                  </div>
                </UitkSpacing>
              ) : (
                <WizardForm isMobile isTablet />
              )}
            </ViewSmall>
            <ViewMedium>
              {this.isBEXSearchOptimizationForDesktopVariant ? (
                <div>
                  <UitkCard padded overflow className="elevation" style={{ maxWidth: DEFAULT_OPTIMIZATION_WIDTH }}>
                    <div>
                      {pageHeadingDiv}
                      {pageSubHeadingDiv}
                      <WizardForm isMobile={false} isTablet />
                    </div>
                  </UitkCard>{" "}
                </div>
              ) : (
                <WizardForm isMobile={false} isTablet />
              )}
            </ViewMedium>
            <ViewLarge>
              {this.isBEXSearchOptimizationForDesktopVariant ? (
                <div>
                  <UitkCard padded overflow className="elevation" style={{ maxWidth: DEFAULT_OPTIMIZATION_WIDTH }}>
                    <div>
                      {pageHeadingDiv}
                      {pageSubHeadingDiv}
                      <WizardForm isMobile={false} isTablet={false} />
                    </div>
                  </UitkCard>
                </div>
              ) : (
                <WizardForm isMobile={false} isTablet={false} />
              )}
            </ViewLarge>
          </Viewport>
          <QueryRoute query={{ pwaDialog: "guestdialog" }}>
            <UitkSheetTransition isVisible>
              <Layer id="guestpicker-dialog">
                <GuestDialog {...guestDialogProps} />
              </Layer>
            </UitkSheetTransition>
          </QueryRoute>
        </div>
      </div>
    );
  }

  private getModuleNameForTracking = () => {
    const componentName = this.props.templateComponent.type || "";
    // append sticky to the module name if needed
    // doesn't look at the device information since you can't interact with the wizard it if you don't see it.
    return this.wizardContainerIsOutOfWindow ? `${componentName}-sticky` : componentName;
  };

  private isAffinityPageRegExMatch = (context: ExtendedContextStore): boolean => {
    const affinityPathRegEx = /^(.*?(\HotelsAffinity\b)[^$]*)$/;

    return affinityPathRegEx.test(context.pageId);
  };

  private getSubmitButtonText(): string {
    // Uses bucket given by Blossom_Mab_Wizard_Search_Button_Copy experiment (or defaults to 0 which is control)
    // More info about this multi-armed bandit experiment here: https://confluence.expedia.biz/display/~knam/Running+MAB-type+Experiments+in+Blossom+Landing+Pages
    const mabBucket = getExperimentBucket(this.props.context, "Blossom_Mab_Wizard_Search_Button_Copy");

    return this.formatText(searchButtonCopyL10n[mabBucket] || locKeys.submitButton);
  }
}

export const WizardHotelPWA = withStores(
  "analytics",
  "context",
  "compositionStore",
  "wizardState",
  "flexModuleModelStore"
)(observer(WizardHotelPWAComponent));

export default WizardHotelPWA;
