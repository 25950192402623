// External libraries
import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

// UITK
import { UitkFakeInput } from "@egds/react-core/input";
import { UitkToolbar, UitkToolbarProps } from "@egds/react-core/toolbar";
import { UitkSheet, UitkSheetContent } from "@egds/react-core/sheet";

// Components
import { WizardDialogTrigger } from "./WizardDialogTrigger";
import { RoomPicker } from "./RoomPicker";

// Types
import { GuestDialogProps, GuestProps } from "../typings";

// Utils
import { locKeys } from "./l10n";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

export const GuestDialog = (props: GuestDialogProps) => {
  const { formatText } = useLocalization();
  const triggerRef = React.createRef<HTMLElement>();

  const dialogAttributes: UitkToolbarProps = {
    toolbarTitle: formatText(locKeys.dialogTitle),
    navigationContent: {
      navIconLabel: formatText(locKeys.cancelButton),
      onClick: props.onDismiss.bind(this, WizardDialogTrigger.GUESTPICKER),
    },
  };

  return (
    <Viewport>
      <ViewSmall>
        <UitkSheet isVisible triggerRef={triggerRef} type="full">
          <UitkToolbar {...dialogAttributes} />
          <RoomPicker {...props} isMobile />
        </UitkSheet>
      </ViewSmall>
      <ViewMedium>
        <UitkSheet isVisible triggerRef={triggerRef} type="centered">
          <UitkToolbar {...dialogAttributes} />
          <UitkSheetContent>
            <RoomPicker {...props} />
          </UitkSheetContent>
        </UitkSheet>
      </ViewMedium>
    </Viewport>
  );
};

export const GuestField = ({ guestsVal, open, focusInput, invalid, componentWithJs }: GuestProps) => {
  const { formatText } = useLocalization();

  let name: string | undefined;
  let value = guestsVal;
  let classes = "guests-input";

  if (!componentWithJs) {
    name = "adults";
    value = "2";
    classes = "";
  }

  return (
    <UitkFakeInput
      id="destination-field"
      name={name}
      label={formatText(locKeys.guests)}
      value={value}
      className={classes}
      icon="person"
      // invalid={invalid} // missing property in UitkFakeInput, https://jira.expedia.biz/browse/UDS-2220
      onClick={open}
      onChange={open}
      domRef={focusInput}
      aria-label={`${formatText(locKeys.guests)} ${value}`}
    />
  );
};
