import * as React from "react";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkCard } from "@egds/react-core/cards";
import { UitkSpacing } from "@egds/react-core/spacing";

import { WizardHotelPWA } from "../WizardHotelPWA";
import { WizardHotelPWAProps } from "../typings";

export const WizardHotelPWAHotelscom = (props: WizardHotelPWAProps) => {
  const component = (
    <UitkLayoutFlex className="hotelscom" alignItems="center" justifyContent="center" blockSize="full_size">
      <UitkLayoutFlexItem>
        <UitkCard overflow>
          <UitkSpacing padding={{ blockstart: "three", inline: "three" }}>
            <div>
              <WizardHotelPWA {...props} />
            </div>
          </UitkSpacing>
        </UitkCard>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );

  return (
    <Viewport>
      <ViewSmall>{component}</ViewSmall>

      <ViewMedium>
        <UitkSpacing margin={{ inline: "three" }}>{component}</UitkSpacing>
      </ViewMedium>
    </Viewport>
  );
};

export default WizardHotelPWAHotelscom;
