import * as React from "react";
import { UitkFloatingFullWidthButton, UitkTertiaryButton, UitkButtonSize } from "@egds/react-core/button";
import { UitkLink } from "@egds/react-core/link";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { observer, inject } from "mobx-react";
import { action, observable, makeObservable } from "mobx";
import { RoomPickerModel } from "./RoomPickerModel";
import { UitkStepInput } from "@egds/react-core/step-input";
import { UitkSelect } from "@egds/react-core/input";
import { liveAnnounce, Level } from "@egds/react-core/utils";
import { LocalizedText } from "bernie-l10n";
import { GuestDialogProps, Room } from "components/flexComponents/WizardHotelPWA/typings";
import { WizardDialogTrigger } from "./WizardDialogTrigger";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkScrollable } from "@egds/react-core/scrollable";
import { UitkText, UitkHeading } from "@egds/react-core/text";

const locKeys = {
  dialogTitle: "wizard.roomPicker.title",
  roomAdded: "wizard.roomPicker.roomAdded.liveAnnounce",
  roomRemoved: "wizard.roomPicker.roomRemoved.liveAnnounce",
  roomPickerOpened: "wizard.roomPicker.roomPickerOpened.liveAnnounce",
  cancelButton: "wizard.roomPicker.closeButton.a11yLabel",
  doneHeader: "wizard.roomPicker.doneButton.label",
  addRoom: "wizard.roomPicker.addRoomButton.label",
  removeRoom: "wizard.roomPicker.removeRoomButton.label",
  removeRoomAria: "wizard.roomPicker.removeRoomButton.a11yLabel",
  nineOrMoreRooms: "wizard.roomPicker.nineOrMoreRooms",
  selectLabel: "wizard.roomPicker.childAgeInput.label",
  roomHeader: "wizard.roomPicker.room.title",
  roomHeaderAria: "wizard.roomPicker.room.a11yLabel",
  childUpdated: "wizard.roomPicker.childUpdated.liveAnnounce",
  youngestChildAge: "wizard.roomPicker.youngestChildAge.text",
  decreaseChildren: "wizard.roomPicker.decreaseChildren.text",
  decreaseAdults: "wizard.roomPicker.decreaseAdults.text",
  adults: "wizard.roomPicker.adults.text",
  increaseChildren: "wizard.roomPicker.increaseChildren.text",
  increaseAdults: "wizard.roomPicker.increaseAdults.text",
  children: "wizard.roomPicker.children.text",
  childrenCaption: "wizard.roomPicker.children.caption",
};

@inject("analytics", "context")
@observer
export class RoomPicker extends React.Component<GuestDialogProps> {
  private static MIN_CHILD_AGE = 0;

  private static MAX_CHILD_AGE = 17;

  public readonly selection: RoomPickerModel;

  private lastRoomHeaderRef: React.RefObject<HTMLHeadingElement> = React.createRef();

  private formatText: (key: string, ...args: any[]) => string;

  constructor(props: GuestDialogProps) {
    super(props);

    makeObservable(this, {
      selection: observable,
      onRoomAdultsChanged: action,
      onRoomChildrenChanged: action,
      onAddRoom: action,
      onRemoveRoom: action,
      onChildAgeUpdated: action,
    });

    const { formatText } = props.l10n;
    this.formatText = formatText;
    this.selection = new RoomPickerModel(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public componentDidUpdate() {
    if (this.lastRoomHeaderRef.current) {
      this.lastRoomHeaderRef.current.focus();
    }
  }

  public render() {
    const { disableAddRoom, requestNineOrMoreRooms, rooms, roomCount } = this.selection;
    const {
      locale,
      site: { domain },
    } = this.props.context!;

    return (
      <>
        <UitkSpacing padding={{ inline: "four", blockstart: "half", blockend: "twelve" }}>
          <UitkScrollable direction="vertical">
            <section>
              {rooms.map((room, index) => this.room(room, index))}
              <UitkSpacing padding={{ blockstart: "four" }}>
                <UitkText align="right" className="addRoomButton">
                  <UitkTertiaryButton onClick={this.onAddRoom(roomCount)} disabled={disableAddRoom}>
                    {this.formatText(locKeys.addRoom)}
                  </UitkTertiaryButton>
                </UitkText>
              </UitkSpacing>
              {requestNineOrMoreRooms ? (
                <UitkSpacing padding={{ inlineend: "four" }}>
                  <div>
                    <UitkLink align="right">
                      <a
                        href={`https://groups.expedia.${domain.split(".")[2]}/Group-Rate/?locale=${locale}`}
                        target="_blank"
                      >
                        {this.formatText(locKeys.nineOrMoreRooms)}
                      </a>
                    </UitkLink>
                  </div>
                </UitkSpacing>
              ) : null}
              <UitkSpacing padding={{ blockstart: "two", blockend: "six" }}>
                <div />
              </UitkSpacing>
            </section>
          </UitkScrollable>
        </UitkSpacing>
        {this.props.isMobile ? (
          <UitkScrim className="guestsDoneBtn" type={UitkScrimType.BUTTON}>
            <UitkFloatingFullWidthButton onClick={this.onSubmit} size={UitkButtonSize.LARGE}>
              {this.formatText(locKeys.doneHeader)}
            </UitkFloatingFullWidthButton>
          </UitkScrim>
        ) : (
          <UitkFloatingFullWidthButton onClick={this.onSubmit} size={UitkButtonSize.LARGE}>
            {this.formatText(locKeys.doneHeader)}
          </UitkFloatingFullWidthButton>
        )}
      </>
    );
  }

  private room(room: Room, index: number) {
    const { roomCount } = this.selection;

    const roomIndexHeader = index + 1;
    const roomHeaderRef = roomCount > 1 && index === roomCount - 1 ? this.lastRoomHeaderRef : undefined;

    return (
      <UitkSpacing padding={{ block: "four" }}>
        <div key={`room-${roomCount}-${index}`} className="roomPickerRoom" data-testid={`room-${index + 1}`}>
          <UitkHeading
            tag="h3"
            size={8}
            className="roomHeader"
            aria-label={this.formatText(locKeys.roomHeaderAria, roomIndexHeader, roomCount)}
            tabIndex={0}
            domRef={roomHeaderRef}
            data-testid={`room-${index + 1}-header`}
          >
            <span aria-hidden="true">
              <LocalizedText message={locKeys.roomHeader} data={[index + 1]} />
            </span>
          </UitkHeading>
          <span tabIndex={0} className="is-visually-hidden">
            {this.formatText(locKeys.roomHeaderAria, roomIndexHeader, roomCount)}
          </span>

          <UitkStepInput
            id={`adult-input-${roomCount}-${index}`}
            incrementId={`adult-increase-${roomCount}-${index}`}
            decrementId={`adult-decrease-${roomCount}-${index}`}
            label={this.formatText(locKeys.adults)}
            incrementText={this.formatText(locKeys.increaseAdults, index + 1)}
            decrementText={this.formatText(locKeys.decreaseAdults, index + 1)}
            max={14}
            min={1}
            value={room.adults}
            onChange={this.onRoomAdultsChanged(room, index)}
            className="adultStepInput"
          />
          <UitkStepInput
            id={`child-input-${roomCount}-${index}`}
            incrementId={`child-increase-${roomCount}-${index}`}
            decrementId={`child-decrease-${roomCount}-${index}`}
            label={this.formatText(locKeys.children)}
            incrementText={this.formatText(locKeys.increaseChildren, index + 1)}
            decrementText={this.formatText(locKeys.decreaseChildren, index + 1)}
            description={this.formatText(locKeys.childrenCaption)}
            max={6}
            min={0}
            value={room.children.length}
            onChange={this.onRoomChildrenChanged(room, index)}
            className="childStepInput"
          />
          {room.children.length > 0 && (
            <UitkLayoutGrid space="two" columns={2}>
              {room.children.map((childAge, i) => (
                <UitkLayoutGridItem colSpan={1} key={`age-${i}`}>
                  {this.childrenAges(room, childAge, i, index)}
                </UitkLayoutGridItem>
              ))}
            </UitkLayoutGrid>
          )}
          {index !== 0 && (
            <UitkSpacing padding={{ blockstart: "four" }}>
              <UitkText align="right" className="removeRoomButton">
                <UitkTertiaryButton
                  onClick={this.onRemoveRoom(room, roomCount)}
                  aria-label={this.formatText(locKeys.removeRoomAria, index + 1, roomCount)}
                >
                  {this.formatText(locKeys.removeRoom)}
                </UitkTertiaryButton>
              </UitkText>
            </UitkSpacing>
          )}
        </div>
      </UitkSpacing>
    );
  }

  private childrenAges(room: Room, childAge: number, i: number, roomIndex: number) {
    const options = this.childrenAgeSelectOptions();

    return (
      <UitkSelect
        label={this.formatText(locKeys.selectLabel, i + 1)}
        id={`age-${i}`}
        name={`child-${roomIndex}-${i}`}
        value={childAge.toString()}
        onChange={this.onChildAgeUpdated(room, i)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </UitkSelect>
    );
  }

  private childrenAgeSelectOptions() {
    const options = [];
    let name = "";

    for (let i = RoomPicker.MIN_CHILD_AGE; i <= RoomPicker.MAX_CHILD_AGE; i++) {
      name = i === 0 ? this.formatText(locKeys.youngestChildAge) : i.toString();

      options.push({
        value: `${i}`,
        name: `${name}`,
      });
    }

    return options;
  }

  public onRoomAdultsChanged = (room: Room, roomIndex: number) => (e: any) => {
    sendDelayedTrackEvent(this.getRoomPickerTrackingInfo(`hotel.guests.${roomIndex}.adults`), this.props.analytics);
    const num = parseInt(e.target.value, 10);
    if (!isNaN(num)) {
      this.selection.changeAdultsForRoom(room, num);
    }
  };

  public onRoomChildrenChanged = (room: Room, roomIndex: number) => (e: any) => {
    sendDelayedTrackEvent(this.getRoomPickerTrackingInfo(`hotel.guests.${roomIndex}.children`), this.props.analytics);
    const num = parseInt(e.target.value, 10);
    if (!isNaN(num)) {
      this.selection.changeChildrenForRoom(room, num);
    }
  };

  public onAddRoom = (roomCount: number) => (e: any) => {
    sendDelayedTrackEvent(this.getRoomPickerTrackingInfo("hotel.guests.rooms.addroom"), this.props.analytics);
    this.selection.addRoom();

    const roomAddedIndex = roomCount + 1;
    const roomAddedMsg = this.formatText(locKeys.roomAdded, roomAddedIndex);
    liveAnnounce(roomAddedMsg, Level.POLITE);
  };

  public onRemoveRoom = (room: Room, roomCount: number) => (e: any) => {
    sendDelayedTrackEvent(this.getRoomPickerTrackingInfo("hotel.guests.rooms.removeRoom"), this.props.analytics);
    this.selection.removeRoom(room);
    const roomRemovedMsg = this.formatText(locKeys.roomRemoved, room, roomCount);
    liveAnnounce(roomRemovedMsg, Level.POLITE);
  };

  public onChildAgeUpdated(room: Room, index: number) {
    return (e: any) => {
      const age = e.target.value;

      this.selection.changeChildAge(room, index, age);
      e.target.focus();
      const childUpdatedMsg = this.formatText(locKeys.childUpdated, index + 1, age);
      liveAnnounce(childUpdatedMsg, Level.POLITE);
    };
  }

  private resetLiveAnnounce() {
    liveAnnounce("", "off" as any);
  }

  private onDismiss() {
    this.selection.reset();
    if (this.props.onDismiss) {
      this.props.onDismiss(WizardDialogTrigger.GUESTPICKER);
    }
  }

  private onSubmit() {
    sendDelayedTrackEvent(this.getRoomPickerTrackingInfo("hotel.guests.done"), this.props.analytics);

    if (this.props.onSave) {
      const { finalProps, displaySummary } = this.selection;
      this.props.onSave(finalProps.adults, finalProps.children, displaySummary);
      this.resetLiveAnnounce();
    }
  }

  private getRoomPickerTrackingInfo(rfrr: string): FlexTrackingInfo {
    return {
      moduleName: this.props.moduleName,
      action: Action.CLICK,
      rfrr,
    };
  }
}
