import { DatePickerProps } from "../typings";
import * as React from "react";
import { DayName, UitkDatePicker, UitkDatePickerTriggeredSelector } from "@egds/react-core/date-picker";
import { DialogRoute, mergeQueryString, closeDialog } from "bernie-core";
import { Layer } from "bernie-view";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";

/**
 * This component abstracts the UitkNewDatePicker providing a nicer interface to Blossom devs.
 *
 * It takes care of most of the boilerplate required to make UitkNewDatePicker with a single
 * place to provide extension points with callbacks that are relevant for Blossom.
 *
 * **Quirks of the UitkNewDatePicker for considerations (as of version: "8.6.19)**:
 * 1. The Mobile view is broken if no dialog is provided. See routerLinkWrapper and dialogWrapper
 * 2. If `footerText` is not passed ot the `UitkNewDatePicker`, a double "Done" button is created. See: https://jira.expedia.biz/browse/UDS-2152
 *    I Attempt to fix this by providing an empty character but it changes the look and feel of the button.
 */
export const DatePicker = observer((props: DatePickerProps) => {
  const { formatText, getWeekData } = useLocalization();
  // @ts-ignore
  const firstDayOfWeek = parseInt(DayName[getWeekData().firstDay.toUpperCase()], 10);
  const datePickerDialogId = "datepicker";
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;

  const closeMobileDialog = () => {
    closeDialog({ history, location });
  };

  /**
   * Mobile specific implementaiton of the dismiss callback. It should close
   * the mobile dialog and delegate the desktop behavior to the parent component with
   * the appropriate paramenters.
   *
   * note: I did it this way because the `onDismiss` prop in the `DialogRoute` was
   * complaining based on the params that I was able to pass. I was following the example
   * in [this url](http://uitk-react.tools.expedia.com/expedia/en_US/components/new-date-picker?)
   * and I think that it is a difference between TS and JS.
   */
  const onDatePickerDismiss = () => {
    closeMobileDialog();
    props.onDismiss(true);
  };

  /**
   * Mobile decoration of the Submit button. It should close the mobile dialog and delegate
   * the rest of the execution to the parent component.
   * @param startDate Selected start date
   * @param endDate Selected end date
   * @param isMobile Whether the device is mobile or not
   */
  const onSubmitDatePicker = (startDate: Date, endDate: Date, isMobile: boolean) => {
    props.onSubmit(startDate, endDate, isMobile);
  };

  /**
   * Mobile version of the DatePicker *REQUIRES* a dialog so that the dates can be displayed.
   * @param children component to wrap
   * @param dateType Start or End date
   */
  const dialogWrapper = (children: any, dateType: any) => {
    return (
      <DialogRoute id={datePickerDialogId}>
        <Layer id="datepicker-dialog" onDismiss={onDatePickerDismiss}>
          {children}
        </Layer>
      </DialogRoute>
    );
  };

  /**
   * Mobile version of the DatePicker *REQUIRES* a dialog element so that dates can be displayed
   * @param children component to wrap
   */
  const routerLinkWrapper = (children: any) => {
    return (
      <RouterLink
        to={{
          pathname: pathname,
          search: mergeQueryString(search, {
            pwaDialog: datePickerDialogId,
          }),
        }}
      >
        {children}
      </RouterLink>
    );
  };

  const dateFieldProps = {
    startId: UitkDatePickerTriggeredSelector.START,
    startLabel: formatText("datePicker.fromDate.label"),
    startPlaceholder: formatText("datePicker.dateInput.placeholder"),
    endId: UitkDatePickerTriggeredSelector.END,
    endLabel: formatText("datePicker.toDate.label"),
    endPlaceholder: formatText("datePicker.dateInput.placeholder"),
    showDatePicker: props.isOpen,
    startInvalid: props.startInvalidMessage,
    endInvalid: props.endInvalidMessage,
    routerLinkWrapper,
    mediumDateFormatProp: props.mediumDateFormat,
  };

  return (
    <UitkDatePicker
      doubleMonths
      dateFieldProps={dateFieldProps}
      firstDayOfWeek={firstDayOfWeek}
      onOpenCB={props.onOpen}
      onDismissCB={onDatePickerDismiss}
      onSubmitCB={onSubmitDatePicker}
      startDate={props.startDate}
      endDate={props.endDate}
      dialogWrapper={dialogWrapper}
      preventAutoFocus
      datePickerContent={props.datePickerContent}
      footerText={props.footerText}
    />
  );
});

export default DatePicker;
